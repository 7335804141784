import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Box } from "../../components/Core";
import ContactForm from "../../components/ContactForm";

const Contact = ({ hero = true, bg = "dark", ...rest }) => {
  return (
    <>
      <Section hero={hero} bg={bg} {...rest}>
        <Container>
          <Row className="justify-content-center">
            <Col>
              <Box className="pr-lg-5">
                <Title color="light" variant="secSm" mb="2rem">
                  Contact me.
                </Title>
                <Text color="light" mb="2.75rem">
                  Have a project in mind or questions? Let's chat!
                </Text>
                <ContactForm theme="dark" />
              </Box>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Contact;
