import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const Logo = ({ className = "", ...rest }) => {
  return (
    <Link to="/" className={`${className}`} {...rest}>
        <StaticImage src="../../assets/image/sb-media.png" alt="SB Media Logo" width={65}/>
    </Link>
  );
};

export default Logo;
