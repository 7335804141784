import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Section, Title, Text, Span } from "../../components/Core";
import imgPhoto from "../../assets/image/seb-photo.jpg";
import {
    FaInstagram,
    FaLinkedin,
} from "react-icons/fa";

const LinkSocial = styled.a`
  color: ${({
    theme }) => theme.colors.dark} !important;
  font-size: 2rem;
  transition: 0.4s;
  &:hover {
    color: ${({
        theme }) => theme.colors.primary} !important;
    transform: translateY(-5px);
  }
`;

const About = ({
    hero = true,
    ...rest
}) => {
    return (
        <>
            <Section hero={hero} {...rest}>
                <Container>
                    <Row className="align-items-center">
                        <Col lg="6">
                            <div>
                                <img src={imgPhoto} alt="Headshot" className="img-fluid" />
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="pl-lg-4 pt-5 pt-lg-0">
                                <Title color="dark" variant="secSm">
                                    Sebastien Berthiaume
                                </Title>
                                <Text color="dark" className="mt-3 mt-lg-5" css={` line-height: 1.5; `}>
                                    Tech-savvy content marketer specializing in production with a passion for action
                                    sports and the outdoors. Wide variety of content creation skills including
                                    photography, videography, editing, storytelling, web development and social media
                                    marketing.
                                </Text>
                                <Text color="dark" className="mt-4">
                                    I pride myself on my
                                    ability to bring strong attention to detail and a creative mind when
                                    contributing to the growth of brands through storytelling and organic content.
                                </Text>
                                <div className="mt-4">
                                    <Text color="dark">Email me at</Text>

                                    <Text variant="p">
                                        <a href="mailto:sebastien.berth94@gmail.com" className="font-weight-bold">
                                            <Span color="dark">sebastien.berth94@gmail.com</Span>
                                        </a>
                                    </Text>
                                </div>
                                <div className="mt-5 d-flex">
                                    <LinkSocial
                                        href="https://www.instagram.com/seebass3/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="me-2">
                                        <FaInstagram />
                                    </LinkSocial>
                                    <LinkSocial
                                        href="https://www.linkedin.com/in/s%C3%A9bastien-berthiaume-99123a217/"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaLinkedin />
                                    </LinkSocial>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    );
};

export default About;
